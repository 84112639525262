import React from 'react'
import BlogFeed from '../components/blog-feed'
import CTA from '../components/cta'
import Layout from '../components/layout'
import Seo from '../components/seo'
import patternHex from '../images/backrounds/hire-a-developer-scaled.jpg'

const Index = ({ location }) => {
  return (
    <Layout>
      <Seo
        pathName={location.pathname}
        title='Hire a Developer'
        description='Call us when you need to hire a developer, engineer, programmer, coder, solutions architect, or consultant. We&#039;re here to help!'
      />
      <section>
        <div
          className='hero'
          style={{
            backgroundImage: `url(${patternHex})`,
          }}
        >
          <div className='hero-title bg-primary-transparent'>
            <h1 className='text-white'>Hire a developer</h1>
            <p className='text-white'>
              Make 729 Solutions the place you go to hire a developer, engineer, programmer, coder, solutions architect, or consultant. Whether you’re looking
              for some short-term assistance or long-term horsepower, we’ve got the talent to fill in behind your team on any project, large or small.
            </p>
            <CTA href='/contact-us/' text='Learn More' className='btn btn-secondary-light' />
          </div>
        </div>
      </section>
      <section className='mt-6'>
        <div className='container'>
          <div className='row'>
            <div className='col-12'>
              <h2>How It Works</h2>
              <ol className='text-primary'>
                <li className='mb-3'>
                  <span className='text-dark'>
                    <b>What kind of developer do you need to hire?</b> We’ve got front and back end developers to support you from start-to-finish, as well as
                    solutions architects to help you structure your project.
                  </span>
                </li>
                <li className='mb-3'>
                  <span className='text-dark'>
                    We’ll scour our team to <b>see who’s skill set matches your needs perfectly</b> to help you get where you need to be. Whether it’s a
                    specific language or platform, we’ve got you covered.
                  </span>
                </li>
                <li>
                  <span className='text-dark'>
                    <b>We can work within your stack, or make a recommendation for you.</b> We’re versatile, flexible, and will collaborate in whatever way best
                    suits your needs.
                  </span>
                </li>
              </ol>
              <CTA href='/contact-us/' text='Schedule a Call' className='btn btn-primary' />
            </div>
          </div>
        </div>
      </section>
      <section
        className='d-flex bg-pattern-trihex bg-dark my-6'
        style={{
          backgroundSize: '280px',
        }}
      >
        <div className='container py-6'>
          <div className='row'>
            <div className='col text-center'>
              <h1 className='text-white'>WE’LL SET YOU UP WITH DEVELOPERS OF THE HIGHEST CALIBER.</h1>
              <p className='text-white'>We can help with any project – from mobile apps and responsive websites to large infrastructure platforms.</p>
              <CTA href='/contact-us/' text='Request a Call' className='btn btn-primary' />
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className='container'>
          <div className='row'>
            <div className='col-12 text-center'>
              <h2>Need a developer with a specific skill set?</h2>
              <p>Our team has experience in the following areas of expertise – and we’re happy to introduce you to them today!</p>
            </div>
          </div>
          <div className='row mt-6'>
            <div className='col-12 col-lg-4'>
              <h3>Tools</h3>
              <p>
                AWS
                <br />
                Browsershots
                <br />
                Browserstack
                <br />
                Capistrano
                <br />
                Chef
                <br />
                Drupal
                <br />
                New
                <br />
                Relic
                <br />
                Nouvola
                <br />
                Puppet
                <br />
                Salesforce
                <br />
                QTest
                <br />
                WordPress
                <br />
                Zendesk
                <br />
              </p>
            </div>
            <div className='col-12 col-lg-4 mt-5 mt-lg-0'>
              <h3>Computer Languages</h3>
              <p>
                ASP
                <br />
                C<br />
                CSS
                <br />
                C++
                <br />
                C#
                <br />
                HTML
                <br />
                Java
                <br />
                JavaScript
                <br />
                PHP
                <br />
                Python
                <br />
                Ruby
                <br />
                Swift/Obejctive-C
                <br />
                Visual Basic
                <br />
              </p>
            </div>
            <div className='col-12 col-lg-4 mt-5 mt-lg-0'>
              <h3>Frameworks / Platforms</h3>
              <p>
                Angular
                <br />
                Bootstrap
                <br />
                .net
                <br />
                Node.js
                <br />
                React.js
                <br />
                Ruby on Rails
                <br />
              </p>
              <h3>Databases</h3>
              MySQL
              <br />
              Oracle
              <br />
              Postgres
              <br />
              SQLServer
              <br />
            </div>
          </div>
          <div className='row mt-5 mb-6'>
            <div className='col-12 text-center'>
              <CTA href='/contact-us/' text='Schedule A Call' className='btn btn-primary' />
            </div>
          </div>
        </div>
      </section>

      <BlogFeed />
    </Layout>
  )
}

export default Index
